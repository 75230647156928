import React from "react";
import Navbar from "../components/Navbar";
import { Footer } from "../components/Footer";

const Aboutuspage = () => {
    return (
        <>
        <Navbar />
        <Footer />
        </>
    );
};

export default Aboutuspage;