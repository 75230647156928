import React from "react";
import Navbar from "../components/Navbar";
import { Footer } from "../components/Footer";

import { TwitchContainer, TwitchTopMarginer, TwitchBottomMarginer } from "../pageStyles/live";

import "../pageStyles/live.css";


const Livepage = () => {
    return (
        <>
            <Navbar />
                <TwitchContainer>
                    
                </TwitchContainer>
            <Footer />
        </>
    );
};

export default Livepage;    