import React from 'react';

import "../pageStyles/schedule.js";
import "../pageStyles/schedule.css";


export default class Schedulepage extends React.Component {	

	render() {
		return (
		<>
		</>
		);
	}
}